
import { infinityScrollTable, helperMixin } from "@/mixins";
import { CompanyPaginate } from "@/models/company";
import { defineComponent } from "vue";
import CompanyHttp from "@/http/company";
import ConfirmModal from "@/components/ConfirmModal.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import { BasePaginateFilter } from "@/interfaces";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import BaseToggleSwitch from "@/components/Base/BaseToggleSwitch.vue";
import { Menu, MenuAccess } from "@/models/MenuAccess";
import menuAccessHttp from "@/http/menuAccess";
import AvatarImg from "@/components/AvatarImg.vue";
import { toast } from "@/template/app";

export default defineComponent({
  components: {
    ConfirmModal,
    BaseTable,
    BaseTableRow,
    BaseTableColumn,
    DropdownItem,
    BaseToggleSwitch,
    AvatarImg
  },
  mixins: [
    helperMixin,
    infinityScrollTable<CompanyPaginate, BasePaginateFilter>(CompanyHttp())
  ],
  computed: {
    menuAccessHttp
  },
  data() {
    return {
      currentMenuAccessSave: null as null | {
        company_id: any;
        menu_id: any;
      },
      menus: [] as Menu[]
    };
  },
  methods: {
    async setAccess(menuAccess: MenuAccess) {
      this.currentMenuAccessSave = {
        company_id: menuAccess.company_id,
        menu_id: menuAccess.menu_id
      };

      if (
        await this.requestHandler(
          this.menuAccessHttp.update(
            {
              company_id: menuAccess.company_id,
              menu_id: menuAccess.menu_id,
              permission: menuAccess.permission
            },
            menuAccess.id
          )
        )
      ) {
        this.currentMenuAccessSave = null;

        return;
      }

      menuAccess.permission = menuAccess.permission ? 0 : 1;

      toast("error", this.trans("layout.error"), 0);
    },

    async onMounted() {
      this.menus = await this.menuAccessHttp.getMenus();
    }
  }
});
