import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_avatar_img = _resolveComponent("avatar-img")!
  const _component_base_toggle_switch = _resolveComponent("base-toggle-switch")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_confirm_modal = _resolveComponent("confirm-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_table, {
      colspan: String(2 + _ctx.menus.length),
      onLoadMore: _ctx.loadMore,
      loading: _ctx.loading,
      hasMore: _ctx.hasMore,
      noDataAvailable: _ctx.noDataAvailable,
      noResultFound: _ctx.noResultFound,
      hover: ""
    }, {
      header: _withCtx(() => [
        _createVNode(_component_base_table_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, { type: "label" }),
            _createVNode(_component_base_table_column, {
              type: "label",
              class: "pl-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.company.name")), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu, i) => {
              return (_openBlock(), _createBlock(_component_base_table_column, {
                type: "label",
                "text-align": "center",
                key: i
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.trans(`menu.${menu.title}`)), 1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
          return (_openBlock(), _createBlock(_component_base_table_row, {
            key: row.id,
            onClick: _withModifiers(($event: any) => (
        _ctx.useRouter().push({
          name: _ctx.RouteNames.CompanyDetail,
          params: {
            id: row.id
          }
        })
      ), ["stop"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_table_column, { class: "w-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_avatar_img, {
                    src: row.logo_link,
                    alt: `${row.name}_logo`
                  }, null, 8, ["src", "alt"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { class: "pl-0" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.name), 1)
                ]),
                _: 2
              }, 1024),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.menu_access, (menuAccess, j) => {
                return (_openBlock(), _createBlock(_component_base_table_column, {
                  key: j,
                  textAlign: "center",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_base_toggle_switch, {
                      modelValue: menuAccess.permission,
                      "onUpdate:modelValue": ($event: any) => ((menuAccess.permission) = $event),
                      onChange: _withModifiers(($event: any) => (_ctx.setAccess(menuAccess)), ["stop"])
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["colspan", "onLoadMore", "loading", "hasMore", "noDataAvailable", "noResultFound"]),
    _createVNode(_component_confirm_modal, {
      type: "delete",
      title: _ctx.trans('layout.modal_title.unactive_company'),
      modelValue: _ctx.confirmDeleteModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmDeleteModal) = $event)),
      onYes: _ctx.deleteSelectedRow
    }, null, 8, ["title", "modelValue", "onYes"])
  ], 64))
}